body {
  font-family: sans-serif;
  font-size: 32px;
  margin: 100px auto 0;
  width: 550px;
}

#banner {
  height: 315px; /* Height of champagne glass image */
  margin-bottom: 100px;
  position: relative;
}

#left-glass {
  left: -25px;
  position: absolute;
}

#right-glass {
  position: absolute;
  right: -25px;
  transform: scale(-1, 1);
}

#year {
  bottom: 200px;
  position: absolute;
  text-align: center;
  width: 100%;
}

#info {
  text-align: center;
}
