body {
  width: 550px;
  margin: 100px auto 0;
  font-family: sans-serif;
  font-size: 32px;
}

#banner {
  height: 315px;
  margin-bottom: 100px;
  position: relative;
}

#left-glass {
  position: absolute;
  left: -25px;
}

#right-glass {
  position: absolute;
  right: -25px;
  transform: scale(-1, 1);
}

#year {
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 200px;
}

#info {
  text-align: center;
}

/*# sourceMappingURL=index.ce8a35b1.css.map */
